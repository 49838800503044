import React from 'react';

interface InfoModalProps {
    showInfoModal: boolean;
    toggleInfoModal: () => void;
    isMobile: boolean;
}

const InfoModal: React.FC<InfoModalProps> = ({ showInfoModal, toggleInfoModal, isMobile }) => {
    return (
        <>
            {showInfoModal && (
                <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: 9998,
                }}
                    onClick={toggleInfoModal}
                />
            )}
            {showInfoModal && (
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "90%" : "50%",
                    height: isMobile ? "90%" : "80%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                    overflowY: "auto",
                    zIndex: 9999,
                }}>
                    <div style={{ padding: "20px", textAlign: "center" }}>
                        <span style={{ float: "right", cursor: "pointer" }} onClick={toggleInfoModal}>X</span>
                        <div style={{ textAlign: "center", display: "flex", flex: 1, flexDirection: "column" }}>
                            <p style={{ marginTop: "8px", color: "purple", fontWeight: "800", fontSize: "30px" }}>
                                Welcome to FENNEC FUNHOUSE!
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                What's the Buzz About Fennec Funhouse?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                Fennec Funhouse is the ultimate hangout for Fennec Blockchain enthusiasts! It's where the fun meets the foxes, and you get to compete in thrilling games and tasks while earning awesome rewards!
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                Who's Behind the Magic and Gathering the Goodies?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                Currently, it's a labor of love! We're all about keeping the Fennec project alive and kicking, so for now, no one's pocketing any profits. But hey, keeping the lights on and adding new features isn't free, so who knows what the future holds?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                How Much Dough Do I Need?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                Most of the events run on community funding, but for some of the events the answer is zilch! Nada! Fennec Funhouse runs on community spirit, so you can join the party without spending a cent. But hey, the more the merrier, right? Your contributions help make the prizes bigger and the competition fiercer!
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                When's the Next Shindig?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                We'll shout it from the rooftops! Keep an eye out for announcements about our weekly events. And who knows? If we're feeling extra feisty and the crowd's roaring, we might just turn this into a non-stop party!
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                How Do I Get Ready to Rock?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                Since Fennec Blockchain isn't quite ready for smart contracts, you'll be your own prize-pickin' champ! We suggest grabbing a mobile wallet for your Android or iOS device and gettin' ready to snag those rewards! While you can also utilize the web wallet, it is not the recommended option.*
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "500" }}>
                                *The web wallet requires using an authenticator. By default it is not set for the reward wallets, but to claim your rewards, you need to add one as an extra step.
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                Can I Share the Glory?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                Sorry, partner! It's a one-fox show. But hey, if the winner's feeling generous and wants to spread the love, who are we to stop 'em? It's all about freedom, baby!
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                So, how do I Claim my Prizes?
                            </p>
                            <p style={{ marginTop: "0px", color: "purple" }}>
                                It's super easy! When you complete an event, you'll be provided an email address and a password for the wallet where the funds are! But beware, it's not just you; everyone who completes the event receives them. When you log in to the wallet, just send the coins to your own wallet as that's the safest place on Earth for them! The fastest participant gets the prize in the end!
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InfoModal;
