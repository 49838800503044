import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import FunhouseDashboard from './FunhouseDashboard';
// import SimonSays from './events/SimonSays';
import './index.css';
import Game from './games/fennecjump/Game';
import FunhouseDashboardOld from './FunhouseDashboardOld';
// import GuessTheNumber from './events/New';
import ScrambleWords from './events/ScrambleWords';

const Index = () => {

  return (
    <Router>
      <Routes>
        {/* <Route
          path="/events/:eventId"
          element={<SimonSays />}
        /> */}
        <Route
          path="/events/:eventId"
          element={<ScrambleWords />}
        />
        <Route
          path="/games/fennecJump"
          element={<Game />}
        />
        <Route path="/" element={<FunhouseDashboardOld />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));
