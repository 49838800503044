import React from 'react';

interface DisclaimerModalProps {
    showDisclaimerModal: boolean;
    toggleDisclaimerModal: () => void;
    isMobile: boolean;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ showDisclaimerModal, toggleDisclaimerModal, isMobile }) => {
    return (
        <>
            {showDisclaimerModal && (
                <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: 9998,
                }}
                    onClick={toggleDisclaimerModal}
                />
            )}
            {showDisclaimerModal && (
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "90%" : "50%",
                    height: isMobile ? "90%" : "80%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                    overflowY: "auto",
                    zIndex: 9999,
                }}>
                    <div style={{ padding: "20px", textAlign: "center" }}>
                        <span style={{ float: "right", cursor: "pointer" }} onClick={toggleDisclaimerModal}>X</span>
                        <div style={{ textAlign: "center", display: "flex", flex: 1, flexDirection: "column" }}>
                            <p style={{ marginTop: "8px", color: "purple", fontWeight: "800", fontSize: "30px" }}>
                                Disclaimer:
                            </p>
                            <p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>
                                The Fennec Funhouse does not constitute an offer to purchase or solicitation to sell, nor is it a recommendation to buy, sell, or use any cryptocurrency or related product.
                            </p><p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>

                                Investing in and using cryptocurrency carries inherent risks, and users must be prepared for the possibility of financial loss. Neither the authors of Fennec Funhouse nor any participants in the Fennec project accept any liability for losses or taxes incurred by holders, purchasers, or sellers of Fennec.
                            </p><p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>

                                Fennec should not be construed as a security. Contributions to the Fennec project or participation in this website's events should not be expected to generate profits solely from the efforts of the Fennec team or any third party.
                            </p><p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>

                                The value of Fennec can fluctuate significantly and may decrease over time. Users should be aware of the high volatility associated with cryptocurrencies.
                            </p><p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>

                                This website is not directed toward any person located in a jurisdiction where purchasing, selling, using, or holding Fennec is prohibited or regulated. Users are advised to familiarize themselves with local laws and regulations before engaging in any cryptocurrency transactions.
                            </p><p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>

                                The Fennec project and Fennec Funhouse are not liable for lost or "burnt" coins, and users are encouraged to exercise caution and diligence in managing their cryptocurrency holdings and/or attempting to Fennec Funhouse events.
                            </p><p style={{ marginTop: "0px", color: "purple", fontWeight: "600" }}>

                                For legal advice regarding cryptocurrency transactions, users should consult with a qualified legal professional.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DisclaimerModal;
