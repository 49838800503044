import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import background from './background.webp';
import LeafSVG from './leaf.webp';
import FennecSitting from './fennecsitting.webp';
import FennecRunningRight from './fennecsitting.webp';
import FennecRunningLeft from './fennecsitting.webp';
import FennecLanding from './fennecsitting.webp';
import FennecJumping from './fennecsitting.webp';

const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400vh;
  overflow: hidden;
  transition: transform 0.1s;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

const Player = styled.div<{ left: number; bottom: number; state: string }>`
  position: absolute;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  left: ${(props) => props.left}px;
  bottom: ${(props) => props.bottom}px;
  transition: left 0.1s;
  z-index: 3;
  background-image: url(${props => {
        switch (props.state) {
            case 'sit':
                return FennecSitting;
            case 'jump':
                return FennecJumping;
            case 'fall':
                return FennecLanding;
            case 'runningRight':
                return FennecRunningRight;
            case 'runningLeft':
                return FennecRunningLeft;
            default:
                return FennecSitting;
        }
    }});
`;

const Leaf = styled.div<{ left: number; bottom: number; isMobile: boolean }>`
  position: absolute;
  width: ${(props) => (props.isMobile ? '100px' : '100px')};
  height: 100px;
  background-image: url(${LeafSVG});
  background-repeat: no-repeat;
  background-size: contain;
  left: ${(props) => props.left}px;
  bottom: ${(props) => props.bottom}px;
`;

const Timer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  color: #FFF;
`;

const RestartButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  padding: 10px 20px;
  background-color: #FF6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;

interface HighScores {
    timestamp: string;
    score: number;
}

const Game: React.FC = () => {
    const [playerState, setPlayerState] = useState('sit');
    const [playerPosition, setPlayerPosition] = useState({ left: window.innerWidth / 2, bottom: 0 });
    const [velocity, setVelocity] = useState(0);
    const [leaves, setLeaves] = useState<{ left: number; bottom: number }[]>([]);
    const [timeLeft, setTimeLeft] = useState(30);
    const [gameOver, setGameOver] = useState(false);
    const [jumpCount, setJumpCount] = useState(0);
    const [score, setScore] = useState(0);
    const [jumping, setJumping] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });
    const requestRef = useRef<number>();
    const previousTimeRef = useRef<number | null>(null);
    const maxHeightRef = useRef<number>(0);
    const [highScores, setHighScores] = useState<HighScores[]>([]);
    const [restart, setRestart] = useState(false);
    const [latestScore, setLatestScore] = useState(0);

    useEffect(() => {
        const storedHighscore = localStorage.getItem('fennecJumpHighscores');
        const storedHighScoreData: HighScores[] = JSON.parse(storedHighscore || '[]');
        setHighScores(storedHighScoreData);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const checkIsMobile = () => {
            setIsMobile(mediaQuery.matches);
        };

        checkIsMobile();
        mediaQuery.addListener(checkIsMobile);

        return () => {
            mediaQuery.removeListener(checkIsMobile);
        };
    }, []);

    useEffect(() => {
        if (restart) {
            const maxJumpHeight = 150;
            const newLeaves = generateLeaves(40, window.innerWidth / 2, 600, maxJumpHeight);
            setLeaves(newLeaves);

            const timer = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        setGameOver(true);
                        clearInterval(timer);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [restart]);

    useEffect(() => {
        if (!restart) {
            const maxJumpHeight = 150;
            const newLeaves = generateLeaves(40, window.innerWidth / 2, 600, maxJumpHeight);
            setLeaves(newLeaves);

            const timer = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        setGameOver(true);
                        clearInterval(timer);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [restart]);

    useEffect(() => {
        if (gameOver) {
            const newHighScores = [...highScores, { timestamp: new Date().toISOString(), score }];
            newHighScores.sort((a, b) => b.score - a.score);

            if (newHighScores.length > 10) {
                newHighScores.pop();
            }

            setHighScores(newHighScores);
            localStorage.setItem('fennecJumpHighscores', JSON.stringify(newHighScores));
            setLatestScore(score);
        }
    }, [gameOver]);

    const generateLeaves = (count: number, maxWidth: number, maxXDistance: number, maxYDistance: number) => {
        const newLeaves = [];
        const viewHeight = window.innerHeight;
        const totalHeight = viewHeight * 4;
        let previousLeaf = { left: Math.random() * maxWidth, bottom: 50 };
        newLeaves.push(previousLeaf);

        for (let i = 1; i < count; i++) {
            let left = Math.random() * maxXDistance + previousLeaf.left - maxXDistance / 2;
            let bottom = previousLeaf.bottom + Math.random() * maxYDistance + 50;
            if (left < 0) left = 0;
            if (left > maxWidth) left = maxWidth;
            if (bottom > totalHeight) bottom = totalHeight - 20;
            newLeaves.push({ left, bottom });
            previousLeaf = { left, bottom };
        }

        return newLeaves;
    };

    const updatePlayerPosition = (time: number) => {
        if (previousTimeRef.current != null) {
            const deltaTime = (time - previousTimeRef.current) / 1000;

            if (gameOver || deltaTime === 0) {
                return;
            }

            let newVelocity = velocity - 500 * deltaTime;
            let newBottom = playerPosition.bottom + velocity * deltaTime;

            newBottom = Math.max(0, newBottom);

            setPlayerPosition((prev) => {
                const onLeaf = leaves.some(
                    (leaf) =>
                        newBottom <= leaf.bottom + 20 &&
                        newBottom >= leaf.bottom &&
                        prev.left + 50 > leaf.left &&
                        prev.left < leaf.left + 100
                );

                if (onLeaf && newVelocity < 0) {
                    newBottom = leaves.find(
                        (leaf) =>
                            newBottom <= leaf.bottom + 20 &&
                            newBottom >= leaf.bottom &&
                            prev.left + 50 > leaf.left &&
                            prev.left < leaf.left + 100
                    )!.bottom + 20;
                    newVelocity = 0;
                    setJumpCount(0);
                    setJumping(false);
                }

                if (newBottom <= 0) {
                    newBottom = 0;
                    newVelocity = 0;
                    setJumpCount(0);
                    setJumping(false);
                }

                if (newVelocity >= 0) {
                    setJumping(false);
                }

                if (newBottom > maxHeightRef.current) {
                    maxHeightRef.current = newBottom;
                    setScore(Math.round(maxHeightRef.current));
                }

                return { ...prev, bottom: newBottom };
            });

            setVelocity(newVelocity);

            const gameContainer = document.getElementById('game-container');
            if (gameContainer) {
                const playerCenter = newBottom + 25;
                const viewportCenter = window.innerHeight / 2;
                const offset = Math.min(0, viewportCenter - playerCenter);
                const oppositeOffset = -offset;

                gameContainer.style.transform = `translateY(${oppositeOffset}px)`;
            }
        }

        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(updatePlayerPosition);
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        if (gameOver) return;

        if (e.key === 'ArrowLeft') {
            setPlayerPosition((prev) => ({
                ...prev,
                left: Math.max(0, prev.left - 50)
            }));
            setPlayerState('runningLeft'); // Change state to 'runningLeft' when moving left
        } else if (e.key === 'ArrowRight') {
            setPlayerPosition((prev) => ({
                ...prev,
                left: Math.min(window.innerWidth - 50, prev.left + 50)
            }));
            setPlayerState('runningRight'); // Change state to 'runningRight' when moving right
        } else if (e.key === 'ArrowUp' && jumpCount < 2) {
            setVelocity(300);
            setJumpCount(jumpCount + 1);
            setJumping(true);
            setPlayerState('jump'); // Change state to 'jump' when jumping
        }
    };

    useEffect(() => {
        if (!gameOver) {
            requestRef.current = requestAnimationFrame(updatePlayerPosition);
            return () => cancelAnimationFrame(requestRef.current!);
        }
    }, [velocity, leaves, gameOver]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [jumpCount, gameOver, jumping]);

    useEffect(() => {
        const gameContainer = document.getElementById('game-container');
        if (gameContainer) {
            const offset = gameContainer.scrollHeight - window.innerHeight;
            window.scrollTo({ top: offset, behavior: 'smooth' });
        }

        const handleWheel = (event: WheelEvent) => {
            event.preventDefault();
        };

        window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleRestart = () => {
        // Reset game state
        setGameOver(false);
        setPlayerPosition({ left: window.innerWidth / 2, bottom: 0 });
        setVelocity(0);
        setLeaves(generateLeaves(40, window.innerWidth - 100, 600, 150));
        setTimeLeft(30);
        setJumpCount(0);
        setScore(0);
        setJumping(false);
        setRestart(!restart);

        // Reset max height reference
        maxHeightRef.current = 0;

        // Restart game loop
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        previousTimeRef.current = null;
        requestRef.current = requestAnimationFrame(updatePlayerPosition);
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        const touch = event.touches[0];
        setTouchStart({ x: touch.clientX, y: touch.clientY });
    };

    const handleTouchEnd = (event: React.TouchEvent) => {
        const touch = event.changedTouches[0];
        const deltaX = touch.clientX - touchStart.x;
        const deltaY = touch.clientY - touchStart.y;

        // Define thresholds for diagonal swipes
        const diagonalThreshold = 50;
        const verticalThreshold = 20;

        if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > diagonalThreshold) {
            // Horizontal swipe
            if (deltaX > 0) {
                // Swipe right
                setPlayerPosition((prev) => ({
                    ...prev,
                    left: Math.min(window.innerWidth - 50, prev.left + 50)
                }));
            } else {
                // Swipe left
                setPlayerPosition((prev) => ({
                    ...prev,
                    left: Math.max(0, prev.left - 50)
                }));
            }
        } else if (Math.abs(deltaY) > Math.abs(deltaX) && Math.abs(deltaY) > verticalThreshold) {
            // Vertical swipe
            if (deltaY < 0 && jumpCount < 2) {
                // Swipe up
                setVelocity(300);
                setJumpCount(jumpCount + 1);
                setJumping(true);
            }
        } else if (deltaX > 0 && deltaY < 0 && jumpCount < 2) {
            // Diagonal swipe right-up
            setPlayerPosition((prev) => ({
                ...prev,
                left: Math.min(window.innerWidth - 50, prev.left + 50)
            }));
            setVelocity(300);
            setJumpCount(jumpCount + 1);
            setJumping(true);
        } else if (deltaX < 0 && deltaY < 0 && jumpCount < 2) {
            // Diagonal swipe left-up
            setPlayerPosition((prev) => ({
                ...prev,
                left: Math.max(0, prev.left - 50)
            }));
            setVelocity(300);
            setJumpCount(jumpCount + 1);
            setJumping(true);
        }
    };

    useEffect(() => {
        const handleTouchMove = (event: TouchEvent) => {
            event.preventDefault();
        };

        const handleTouchStart = (event: TouchEvent) => {
            event.preventDefault();
        };

        const gameContainer = document.getElementById('game-container');
        if (gameContainer) {
            gameContainer.addEventListener('touchmove', handleTouchMove, { passive: false });
            gameContainer.addEventListener('touchstart', handleTouchStart, { passive: false });

            return () => {
                gameContainer.removeEventListener('touchmove', handleTouchMove);
                gameContainer.removeEventListener('touchstart', handleTouchStart);
            };
        }
    }, []);

    return (
        <div>
            <Background />
            <GameContainer
                id="game-container"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <Player left={playerPosition.left} bottom={playerPosition.bottom} state={playerState} />
                {leaves.map((leaf, index) => (
                    <Leaf key={index} left={leaf.left} bottom={leaf.bottom} isMobile={isMobile} />
                ))}
            </GameContainer>
            <div>
                <Timer>
                    {timeLeft}
                </Timer>
                <RestartButton onClick={handleRestart}>Restart</RestartButton>
                {gameOver && <RestartButton onClick={handleRestart}>Restart</RestartButton>}
                {timeLeft === 0 && (
                    <>
                        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%", marginBottom: "0px", opacity: 0.9 }}>
                            <div style={{ marginTop: "16px", width: isMobile ? "90%" : "50%", padding: "16px", backgroundColor: "white", opacity: 0.9, border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                                {score >= highScores[0]?.score && (
                                    <p style={{ fontSize: 25, marginTop: "8px", marginLeft: "8px", marginRight: "8px", marginBottom: "32px", fontWeight: "600", color: "purple", padding: "0px" }}>
                                        NEW HIGH SCORE!
                                    </p>
                                )}
                                <p style={{ fontSize: 20, marginTop: "8px", marginLeft: "8px", marginRight: "8px", marginBottom: "32px", fontWeight: "600", color: "purple", padding: "0px" }}>
                                    Your score: {latestScore}
                                </p>
                                <p style={{ fontSize: 20, margin: "8px", fontWeight: "600", color: "purple", padding: "0px" }}>
                                    Weekly top 10:
                                </p>
                                {highScores.map((scoreData, index) => (
                                    <p key={index} style={{ marginTop: "16px", marginBottom: "16px", fontWeight: "600", color: "purple", padding: "0px", margin: "0px", textDecoration: (scoreData.score === latestScore ? "underline" : "none") }}>
                                        {`${index + 1}. ${scoreData.score}`}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Game;
