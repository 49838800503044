import React, { useEffect, useState } from 'react';
import { PropagateLoader } from 'react-spinners';

type ToastType = 'success' | 'error' | 'warning' | 'info';

interface InfoModalProps {
    showRegisterModal: boolean;
    triggerCloseModal: () => void;
    isMobile: boolean;
    selectedEventId: string;
    selectedEventName: string | null;
    triggerNotification: (message: string, type: ToastType) => void;
    selectedEventFee: number;
}

const RegisterToEvent: React.FC<InfoModalProps> = ({ selectedEventFee, triggerNotification, showRegisterModal, triggerCloseModal, isMobile, selectedEventId, selectedEventName }) => {
    const [userTransactionHash, setUserTransactionHash] = useState("");
    const [step, setStep] = useState(1);
    const [eventAddress, setEventAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [timeLeft, setTimeLeft] = useState<number>(5 * 60);
    const [timeout, setTimeout] = useState(false);
    const [hashCheckLoading, setHashCheckLoading] = useState(false);
    const [userId, setUserId] = useState("notSet");

    useEffect(() => {
        const storedTransactionDataString = localStorage.getItem('userId');
        if (storedTransactionDataString) {
            setUserId(storedTransactionDataString);
        }
    }, []);

    useEffect(() => {
        const savedUserTransactionHash = localStorage.getItem('userTransactionHash');
        if (savedUserTransactionHash) {
            setUserTransactionHash(savedUserTransactionHash);
        }
    }, []);

    const closeModal = () => {
        setUserTransactionHash("");
        setStep(1);
        setEventAddress("");
        triggerCloseModal();
    }

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (timeout && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeout && timeLeft === 0) {
            setTimeout(false);
            setTimeLeft(5 * 60);
            setLoading(false);
            triggerNotification("Connection timeout!", "error");
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [timeout, timeLeft, triggerNotification]);

    const handleWalletAddressChange = (value: string) => {
        setUserTransactionHash(value);
    };

    const isInputEmpty = userTransactionHash.trim() === "";

    const handleShowAddressClick = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://us-central1-fnncbackend.cloudfunctions.net/api/fetchEventWalletAddress/${selectedEventId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const eventWalletAddress = data.eventAddress;

            setEventAddress(eventWalletAddress);
            setStep(2);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const validateTransaction = async (event: string, eventWalletAddress: string, userTransactionHash: string, userId: string, selectedEventFee: number | undefined) => {
        setHashCheckLoading(true);
        setTimeout(true);
        try {
            let fetchedEventAddress = eventWalletAddress;
            let requestData = {
                event: event,
                eventWalletAddress: selectedEventFee === undefined ? fetchedEventAddress : eventWalletAddress,
                userTransactionHash: userTransactionHash,
                userId: userId,
                selectedEventFee: selectedEventFee === undefined ? 0 : selectedEventFee,
            }

            if (selectedEventFee === 0 || selectedEventFee === undefined) {
                const response = await fetch(`https://us-central1-fnncbackend.cloudfunctions.net/api/fetchEventWalletAddress/${selectedEventId}`);
                const data = await response.json();
                fetchedEventAddress = data.eventAddress;

                requestData = {
                    event: event,
                    eventWalletAddress: fetchedEventAddress,
                    userTransactionHash: userTransactionHash,
                    userId: userId,
                    selectedEventFee: 0,
                }
            }

            const response = await fetch('https://us-central1-fnncbackend.cloudfunctions.net/api/validateTransaction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });
            if (response.ok) {
                const responseData = await response.json();
                if (responseData.message.includes('Waiting for transaction')) {
                    setStep(3);
                    setHashCheckLoading(false);
                } else if (responseData.message.includes('Transaction received')) {
                    const transactionObject = responseData.transaction;
                    var existingTransactionData = JSON.parse(localStorage.getItem('userTransactionData') || '[]');
                    existingTransactionData.push(transactionObject);
                    localStorage.setItem('userTransactionData', JSON.stringify(existingTransactionData));
                    localStorage.setItem('userId', transactionObject.ID);

                    setHashCheckLoading(false);
                    triggerNotification("You have successfully registered to the event!", "success");
                    triggerCloseModal();
                    setTimeout(false);
                    setTimeLeft(5 * 60);
                    setEventAddress("");
                    setUserTransactionHash("");
                    setStep(1);
                }
            } else {
                const errorMessage = await response.text();
                if (errorMessage.includes('Transaction not received') && timeLeft > 0) {
                    window.setTimeout(() => {
                        validateTransaction(selectedEventId, eventAddress, userTransactionHash, userId, selectedEventFee);
                    }, 10000);
                } else if (errorMessage.includes(`Hash "${userTransactionHash}" has already registered for this event.`)) {
                    setHashCheckLoading(false);
                    triggerNotification("You have already registered to the event!", "error");
                    triggerCloseModal();
                    setUserTransactionHash("");
                    setTimeout(false);
                    setTimeLeft(5 * 60);
                    setEventAddress("");
                    setStep(1);
                } else if (errorMessage.includes(`Event not found.`)) {
                    setHashCheckLoading(false);
                    triggerNotification("Event not found!", "error");
                    triggerCloseModal();
                    setUserTransactionHash("");
                    setTimeout(false);
                    setTimeLeft(5 * 60);
                    setEventAddress("");
                    setStep(1);
                } else if (errorMessage.includes(`Error: None of the transaction amounts are equal to or greater than the selected event fee.`)) {
                    setHashCheckLoading(false);
                    triggerNotification("The transaction amount did not match with the requirement!", "error");
                    triggerCloseModal();
                    setUserTransactionHash("");
                    setTimeout(false);
                    setTimeLeft(5 * 60);
                    setEventAddress("");
                    setStep(1);
                } else if (errorMessage.includes(`Internal server error`)) {
                    setHashCheckLoading(false);
                    triggerNotification("An error occured! Please try again.", "error");
                    triggerCloseModal();
                    setUserTransactionHash("");
                    setTimeout(false);
                    setTimeLeft(5 * 60);
                    setEventAddress("");
                    setStep(1);
                } else {
                    setHashCheckLoading(false);
                    triggerNotification(errorMessage, "error");
                    triggerCloseModal();
                    setUserTransactionHash("");
                    setTimeout(false);
                    setTimeLeft(5 * 60);
                    setEventAddress("");
                    setStep(1);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCopyToClipboard = () => {
        if (eventAddress) {
            navigator.clipboard.writeText(eventAddress)
                .then(() => {
                    triggerNotification("Address copied to clipboard", "success");
                })
                .catch(err => {
                    console.error('Failed to copy address to clipboard', err);
                });
        }
    };

    return (
        <div>
            {showRegisterModal && (
                <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: 9994,
                }}
                    onClick={closeModal}
                />
            )}
            {showRegisterModal && (
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                    overflowY: "auto",
                    width: isMobile ? "90%" : "50%",
                    zIndex: 9999,
                }}>
                    <div style={{ padding: "20px", textAlign: "center" }}>
                        <span style={{ float: "right", cursor: "pointer" }} onClick={closeModal}>X</span>
                        <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            {step !== 0 && (
                                <div>
                                    <p style={{ marginBottom: "0px", color: "purple", fontWeight: "800", fontSize: "24px" }}>
                                        REGISTER TO EVENT
                                    </p>
                                    <p style={{ marginTop: "0px", marginBottom: "16px", color: "purple", fontWeight: "800", fontSize: "24px" }}>
                                        Event type: {selectedEventName}
                                    </p>
                                </div>
                            )}
                            {step === 4 && (
                                <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                    You can now make the transaction to the wallet above. Please don't close or update this page. The view will update automatically once the transaction is complete.
                                </p>
                            )}
                            {step === 0 && (
                                <p style={{ marginTop: "8px", marginBottom: "8px", color: "purple", fontWeight: "800", fontSize: "24px" }}>
                                    Unfortunately your wallet is not eiliglibe for the event. Please make sure you have at least 1 full FNNC in your wallet to participate.
                                </p>
                            )}
                            {step === 9 && (
                                <p style={{ marginTop: "8px", marginBottom: "8px", color: "purple", fontWeight: "800", fontSize: "24px" }}>
                                    Seems like you have already registered to this event! Great! Now all we have to do is to wait for the event to start.
                                </p>
                            )}
                            {step === 1 && selectedEventFee > 0 && (
                                <div>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        Click the button below to show the wallet address of this event.
                                    </p>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        Once the address is shown, you can submit your contribution.
                                    </p>
                                </div>
                            )}
                            {step === 1 && selectedEventFee === 0 && (
                                <div>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        If you want to make a contribution to this event, reveal the wallet address by pressing the button below.
                                    </p>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        Once the address is shown, you can submit your contribution.
                                    </p>
                                </div>
                            )}
                            {step === 2 && selectedEventFee > 0 && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        Make your contribution of AT LEAST {selectedEventFee} FNNC to the address below. Once the transaction has been made, copy the transaction hash and press "validate transaction".
                                    </p>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        Transactions will not be refunded and cannot be added up, so make sure to transfer the correct amount in a single transaction.
                                    </p>
                                </div>
                            )}
                            {step === 2 && selectedEventFee === 0 && (
                                <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                    Make your contribution to the address below. Once the transaction has been made, copy the transaction hash and press "validate transaction". Transactions will not be refunded and cannot be added up, so make sure to transfer the correct amount in a single transaction.
                                </p>
                            )}
                            {step === 1 && selectedEventFee < 0 && (
                                <p style={{ marginTop: "0px", color: "purple" }}>
                                    Transactions done before revealing the event address cannot be confirmed!
                                </p>
                            )}
                            {((step === 1 || step === 2) && (
                                <div style={{
                                    marginTop: "16px",
                                    width: isMobile ? "90%" : "80%",
                                    padding: "16px",
                                    backgroundColor: "purple",
                                    border: "2px solid purple",
                                    borderRadius: "16px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    height: "20px",
                                    alignContent: "center",
                                }}
                                    onClick={eventAddress ? handleCopyToClipboard : handleShowAddressClick}>

                                    <p style={{ fontSize: isMobile ? "10px" : "inherit", marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px" }}>
                                        {loading && isInputEmpty ? (
                                            <PropagateLoader color="white" />
                                        ) : (eventAddress ? eventAddress : "Click to show wallet address")}
                                    </p>
                                </div>
                            ))}
                            {step === 1 && selectedEventFee === 0 && (
                                <div>
                                    <p style={{ marginBottom: "8px", color: "purple", fontWeight: "600" }}>
                                        ...Or just reserve your seat to the event for free.
                                    </p>
                                </div>
                            )}
                            {step === 2 && (
                                <input
                                    type="text"
                                    placeholder="Enter your transaction hash here"
                                    onChange={(e) => handleWalletAddressChange(e.target.value)}
                                    value={userTransactionHash}
                                    style={{
                                        marginBottom: "16px",
                                        marginTop: "32px",
                                        padding: "12px",
                                        borderRadius: "6px",
                                        border: "1px solid #ccc",
                                        width: "80%",
                                        maxWidth: "300px",
                                        boxSizing: "border-box",
                                        fontSize: "16px",
                                        outline: "none"
                                    }}
                                />
                            )}
                            {(step === 2 || (step === 1 && selectedEventFee === 0)) && (
                                <div
                                    style={{
                                        marginTop: "16px",
                                        width: isMobile ? "80%" : "50%",
                                        padding: "16px",
                                        backgroundColor: selectedEventFee === 0 ? "purple" : isInputEmpty ? "#ccc" : "purple",
                                        border: "2px solid purple",
                                        borderRadius: "16px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        textAlign: "center",
                                        cursor: selectedEventFee === 0 ? "pointer" : isInputEmpty ? "not-allowed" : "pointer",
                                        height: "20px",
                                    }}
                                    onClick={() => selectedEventFee === 0 || selectedEventFee === undefined ? validateTransaction(selectedEventId, eventAddress, userTransactionHash, userId, selectedEventFee) : isInputEmpty ? undefined : validateTransaction(selectedEventId, eventAddress, userTransactionHash, userId, selectedEventFee) }
                                >
                                    <p style={{ marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px" }}>
                                        {hashCheckLoading ? (
                                            <PropagateLoader color="white" />
                                        ) : ((selectedEventFee === 0 ? "Register to the event" : "Validate transaction"))}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default RegisterToEvent;
