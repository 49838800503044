import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InsideCave from '../../src/inside.webp';

const wordsList = [
  'fennec', 'fox', 'blockchain', 'fnnc', 'crypto',
  'money', 'fun', 'castle', 'promise', 'yellow',
  'jump', 'ceiling', 'effect', 'grass', 'original',
  'ocean', 'event', 'currency', 'radiant', 'funny'
];

const scrambleWord = (word: string): string => {
  return word.split('').sort(() => Math.random() - 0.5).join('');
};

const ScrambleWords: React.FC = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const [scrambledWord, setScrambledWord] = useState<string>('');
  const [guess, setGuess] = useState<string>('');
  const [correctGuesses, setCorrectGuesses] = useState<string[]>([]);
  const [timeLeft, setTimeLeft] = useState<number>(20);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string>('');

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const checkIsMobile = () => {
      setIsMobile(mediaQuery.matches);
    };
    checkIsMobile();
    mediaQuery.addListener(checkIsMobile);
    return () => {
      mediaQuery.removeListener(checkIsMobile);
    };
  }, []);

  useEffect(() => {
    if (correctGuesses.length === 5) {
      setGameOver(true);
    }
  }, [correctGuesses]);

  useEffect(() => {
    setScrambledWord(scrambleWord(wordsList[currentWordIndex]));
    setTimeLeft(20);
    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [currentWordIndex]);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (!gameOver) {
        toast.error('Time is up! Game Over.', { autoClose: 3000 });
        setGameOver(true);
      }
    }
  }, [timeLeft]);

  const handleGuessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuess(e.target.value);
  };

  const handleGuessSubmit = () => {
    if (guess.toLowerCase() === wordsList[currentWordIndex]) {
      toast.success('Correct!', { autoClose: 3000 });
      setCorrectGuesses([...correctGuesses, wordsList[currentWordIndex]]);
      setCurrentWordIndex((prev) => prev + 1);
    } else {
      toast.error('Incorrect, try again!', { autoClose: 3000 });
    }
    setGuess('');
  };

  const handleWalletAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletAddress(e.target.value);
  };

  const handleCall = async () => {
    try {
      const response = await fetch('https://us-central1-fnncbackend.cloudfunctions.net/api/ScrambleWords', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress }),
      });
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message.includes('Success')) {
          toast.success('You are the winner! Congratulations!', { autoClose: 3000 });
        } else if (responseData.message.includes('Fail')) {
          toast.error('Unfortunately someone was faster than you!', { autoClose: 3000 });
        }
      } else {
        toast.error('Failed to submit wallet address!', { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRestart = () => {
    setCurrentWordIndex(0);
    setGuess('');
    setCorrectGuesses([]);
    setGameOver(false);
    setWalletAddress('');
  };

  return (
    <div style={{
      backgroundImage: `url(${InsideCave})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }}>
      {!gameOver ? (
        <div style={{
          width: isMobile ? '90%' : '50%',
          padding: '16px',
          backgroundColor: 'white',
          opacity: 0.9,
          border: '2px solid purple',
          borderRadius: '16px',
          textAlign: 'center',
          maxHeight: '70vh',
          overflowY: 'auto',
          marginTop: "128px",
          marginBottom: "auto"
        }}>
          <h1>Word Scramble</h1>
          <p>Unscramble the word: {scrambledWord}</p>
          <p>You have {timeLeft} seconds left</p>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <input
              type="text"
              value={guess}
              onChange={handleGuessChange}
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <button
              onClick={handleGuessSubmit}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                marginLeft: '10px',
                cursor: 'pointer',
                backgroundColor: 'purple',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                marginTop: "16px"
              }}
            >
              Submit Guess
            </button>
          </div>
        </div>
      ) : (
        <div style={{
          width: isMobile ? '90%' : '50%',
          padding: '16px',
          backgroundColor: 'white',
          opacity: 0.9,
          border: '2px solid purple',
          borderRadius: '16px',
          textAlign: 'center',
          maxHeight: '70vh',
          overflowY: 'auto'
        }}>
          <p style={{ fontSize: 20, marginTop: '8px', fontWeight: '600', color: 'purple', padding: '0px' }}>
            {correctGuesses.length === 5 ? 'Congratulations! You won!' : 'Game Over!'}
          </p>
          <p style={{ color: 'purple', marginTop: '16px', padding: '0px' }}>
            {correctGuesses.length === 5 ? 'If you were the first one to finish, the prize will be paid within 48 hours.' : ''}
          </p>
          {correctGuesses.length === 5 && (
            <input
              type="text"
              placeholder="Enter your wallet address here"
              onChange={handleWalletAddressChange}
              value={walletAddress}
              style={{
                marginBottom: "16px",
                marginTop: "32px",
                padding: "12px",
                borderRadius: "6px",
                border: "1px solid #ccc",
                width: "80%",
                maxWidth: "300px",
                boxSizing: "border-box",
                fontSize: "16px",
                outline: "none"
              }}
            />
          )}
          {correctGuesses.length === 5 && (
            <div style={{
              marginTop: "16px",
              width: isMobile ? "90%" : "80%",
              padding: "16px",
              backgroundColor: "purple",
              border: "2px solid purple",
              borderRadius: "16px",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              cursor: "pointer",
              height: "20px",
              alignContent: "center",
            }}
              onClick={handleCall}
            >
              <p style={{ fontSize: isMobile ? "10px" : "inherit", marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px" }}>
                Submit
              </p>
            </div>
          )}
          <button
            onClick={handleRestart}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              marginTop: '16px'
            }}
          >
            Start Over
          </button>
        </div>
      )}
      <ToastContainer position="bottom-center" autoClose={3000} />
    </div>
  );
};

export default ScrambleWords;
