import { useEffect, useRef, useState } from 'react';
import FunhouseBackground from '../src/background.webp';
import FunhouseLogo from '../src/funhouselogo.png';
import InfoModal from './subcomponents/InfoModal';
import RegisterToEvent from './subcomponents/RegisterToEvent';
import { ToastContainer } from "react-toastify";
import { toast, ToastOptions } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PropagateLoader } from 'react-spinners';
import './fadeout.css';
import './funhousedashboard.css';
import DisclaimerModal from './subcomponents/DisclaimerModal';
import Game from './games/fennecjump/Game';

interface Events {
  eventId: string;
  eventDifficulty: string;
  eventName: string;
  eventType: string;
  eventStartTime: string;
  eventStatus: string;
  timeUntilStart: number;
  currentBalance: number;
  eventFee: number;
}

interface PriceDetails {
  circulationSupply: string;
  hashRate: number;
  marketCapNumber: number;
  recentBlockTimeAverage: number;
  updateTime: number;
  usdValue: string;
}

interface UserParticipation {
  transactionHash: string;
  event: string;
}

type ToastType = 'success' | 'error' | 'warning' | 'info';

function FunhouseDashboardOld() {
  const [isMobile, setIsMobile] = useState(false);
  const [utcTime, setUtcTime] = useState<Date>(new Date());
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [eventsList, setEventsList] = useState<Events[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string>("");
  const [selectedEventFee, setSelectedEventFee] = useState<number>(0);
  const [selectedEventName, setSelectedEventName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [userParticipations, setUserPartcipations] = useState<UserParticipation[]>([]);
  const [countdown, setCountdown] = useState("");
  const [priceDetails, setPriceDetails] = useState<PriceDetails[]>([]);
  const [totalAmountInEvents, setTotalAmountInEvents] = useState(0);
  const totalPrizePool = totalAmountInEvents * parseFloat(priceDetails[0]?.usdValue);
  const [eventParticipation, updateEventParticipation] = useState(false);
  const [userId, setUserId] = useState("notSet");
  const [fadeOut, setFadeOut] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [imagePosition, setImagePosition] = useState("50%");

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const checkIsMobile = () => {
      setIsMobile(mediaQuery.matches);
    };

    checkIsMobile();
    mediaQuery.addListener(checkIsMobile);

    return () => {
      mediaQuery.removeListener(checkIsMobile);
    };
  }, []);

  const scrollHandler = () => {
    if (scrollableRef.current) {
      const scrollPosition = scrollableRef.current.scrollTop || 0;
      const maxScroll = (scrollableRef.current.scrollHeight - scrollableRef.current.clientHeight) || 0;
      const scrollPercentage = (scrollPosition / maxScroll) * 100;
      let leftPosition = `${50 - scrollPercentage}%`;

      leftPosition = `${Math.max(parseFloat(leftPosition), 8)}%`;

      setImagePosition(leftPosition);
    }
  };

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.addEventListener("scroll", scrollHandler);
    }
    return () => {
      if (scrollableRef.current) {
        scrollableRef.current.removeEventListener("scroll", scrollHandler);
      }
    };
  }, [scrollableRef]);

  useEffect(() => {
    const storedTransactionDataString = localStorage.getItem('userId');
    if (storedTransactionDataString) {
      setUserId(storedTransactionDataString);
    }
  }, []);

  const handleLinkClick = (eventId: string) => {
    setFadeOut(true);
    setTimeout(() => {
      window.location.href = eventId === "ZddIxF13RvnOlbaIGRbs" ? `/events/${eventId}/phase1` : `/events/${eventId}`;
    }, 1000);
  };

  useEffect(() => {
    const storedTransactionDataString = localStorage.getItem('userTransactionData');
    const storedTransactionData: UserParticipation[] = JSON.parse(storedTransactionDataString || '[]');
    setUserPartcipations(storedTransactionData);
  }, [eventParticipation]);

  useEffect(() => {
    setLoading(true);
    fetch('https://us-central1-fnncbackend.cloudfunctions.net/api/getEvents')
      .then(response => response.json())
      .then((data: Events[]) => {
        data.sort((a, b) => new Date(a.eventStartTime).getTime() - new Date(b.eventStartTime).getTime());
        const updatedData = data.map(event => {
          const eventStartTime = new Date(event.eventStartTime).getTime();
          const currentTime = utcTime.getTime();
          const timeUntilStart = Math.max(eventStartTime - currentTime, 0);
          return { ...event, timeUntilStart };
        });
        const totalAmount = updatedData.reduce((acc, event) => acc + event.currentBalance, 0);
        setTotalAmountInEvents(totalAmount);
        setEventsList(updatedData);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error('Error fetching event time:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetEvents = (() => {
    setLoading(true);
    fetch('https://us-central1-fnncbackend.cloudfunctions.net/api/getEvents')
      .then(response => response.json())
      .then((data: Events[]) => {
        data.sort((a, b) => new Date(a.eventStartTime).getTime() - new Date(b.eventStartTime).getTime());
        const updatedData = data.map(event => {
          const eventStartTime = new Date(event.eventStartTime).getTime();
          const currentTime = utcTime.getTime();
          const timeUntilStart = Math.max(eventStartTime - currentTime, 0);
          return { ...event, timeUntilStart };
        });
        const totalAmount = updatedData.reduce((acc, event) => acc + event.currentBalance, 0);
        setTotalAmountInEvents(totalAmount);
        setEventsList(updatedData);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    setLoading(true);
    fetch('https://us-central1-fnncbackend.cloudfunctions.net/api/getPriceDetails')
      .then(response => response.json())
      .then((data: any[]) => {
        const sortedData = data.sort((a: { updateTime: number }, b: { updateTime: number }) => b.updateTime - a.updateTime);
        sortedData.forEach(item => {
          item.updateTime = new Date(item.updateTime * 1000);
        });
        setPriceDetails(sortedData[0]);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch('https://worldtimeapi.org/api/timezone/utc')
      .then(response => response.json())
      .then(data => {
        setUtcTime(new Date(data.datetime));
      })
      .catch(error => {
      });
  }, []);

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  const toggleDisclaimerModal = () => {
    setShowDisclaimerModal(!showDisclaimerModal);
  };

  const toggleRegisterModal = () => {
    setShowRegisterModal(!showRegisterModal);
  };

  const handleEventSpecs = (eventId: string, eventName: string, eventFee: number | undefined) => {
    setSelectedEventId(eventId);
    setSelectedEventName(eventName);
    if (eventFee === undefined) {
      setSelectedEventFee(0)
    } else {
      setSelectedEventFee(eventFee);
    }
  }

  const triggerNotification = (message: string, type: ToastType) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
    } as ToastOptions);
  }

  const triggerCloseModal = () => {
    setShowRegisterModal(false);
    GetEvents();
    updateEventParticipation(!eventParticipation);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setEventsList(prevEventsList => {
        const updatedEventsList = prevEventsList.map(event => {
          const eventStartTime = event.timeUntilStart - 1000;
          return { ...event, timeUntilStart: eventStartTime };
        });
        return updatedEventsList;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (eventsList.length > 0) {
      const eventStartTime = eventsList[0].timeUntilStart;
      if (eventStartTime > 0) {
        const days = Math.floor(eventStartTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((eventStartTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((eventStartTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((eventStartTime % (1000 * 60)) / 1000);
        setCountdown(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
      } else {
        setCountdown('Event has started!');
      }
    }
  }, [eventsList]);

  const formatCountdown = (timeUntilStart: number) => {
    if (timeUntilStart > 0) {
      const days = Math.floor(timeUntilStart / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeUntilStart % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeUntilStart % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeUntilStart % (1000 * 60)) / 1000);
      return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    } else {
      return 'Event has started!';
    }
  };

  const formatDateTime = (dateTimeString: string) => {
    const dateTime = new Date(dateTimeString);
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      hour12: true
    };
    const formattedDate = dateTime.toLocaleDateString(undefined, dateOptions);
    const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions);
    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    <>
      <div className={`fade-out-overlay${fadeOut ? " active" : ""}`} />
      <div style={{
        backgroundImage: `url(${FunhouseBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        {userId !== "notSet" && (
          <p style={{ padding: "0px", marginTop: "0px", position: "absolute", top: 0, right: isMobile ? undefined : 0 }}>
            {userId}
          </p>
        )}
        <div className={`event-list-container${fadeOut ? " fade-out" : ""}`} style={{ width: "100%", height: "100vh", overflowY: "hidden", display: "flex", flexDirection: "column" }}>
          {!showRegisterModal && (
            <div style={{ width: "100%", height: "100vh", overflowY: "hidden", display: "flex", flexDirection: "column" }}>
              {isMobile && (
                <div style={{ position: "sticky", top: 0, zIndex: 100, display: "flex", justifyContent: "center" }}>
                  <img
                    src={FunhouseLogo}
                    alt="Funhouse Logo"
                    style={{
                      maxWidth: isMobile ? (loading ? '80vw' : '20vw') : (loading ? '30vw' : '20vw'),
                      height: isMobile ? (loading ? '80vw' : '20vw') : (loading ? '30vw' : '20vw'),
                      transition: 'all 1s ease',
                      marginTop: "16px",
                    }}
                  />
                </div>
              )}
              <div style={{ flex: 1, overflowY: "auto" }} ref={scrollableRef}
              >
                {!isMobile && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={FunhouseLogo}
                      alt="Funhouse Logo"
                      style={{
                        maxWidth: isMobile ? (loading ? '80vw' : '50vw') : (loading ? '30vw' : '15vw'),
                        height: isMobile ? (loading ? '80vw' : '50vw') : (loading ? '30vw' : '15vw'),
                        transition: 'all 1s ease',
                        marginTop: "16px",
                        position: isMobile ? "relative" : "absolute",
                        left: imagePosition,
                        transform: isMobile ? "translateX(-100%)" : "translateX(-50%)",
                      }}
                    />
                  </div>
                )}
                {/* <div style={{ width: "100%", marginTop: "16vw", marginBottom: "0px" }}>
                  <div style={{ marginTop: "16px", width: isMobile ? "90%" : "50%", padding: "16px", backgroundColor: "white", opacity: 0.9, border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  </div>
                </div> */}
                {eventsList.map((event, index) => {
                  const hasRegistered = userParticipations.some(participation => participation.event === event.eventId);
                  const prizePool = event.currentBalance * parseFloat(priceDetails[0]?.usdValue);
                  return (
                    <div key={index} style={{ width: "100%", marginTop: index === 0 ? "16vw" : "0px", marginBottom: index === eventsList.length - 1 ? "16px" : "0px" }}>
                      <div style={{ marginTop: index !== 0 ? "16px" : "0px", width: isMobile ? "90%" : "50%", padding: "16px", backgroundColor: "white", opacity: 0.9, border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                        {event.timeUntilStart >= 0 ? (
                          <p style={{ fontSize: 20, margin: "8px", fontWeight: "600", color: "purple", padding: "0px" }}>
                            Event starts at: {formatDateTime(event.eventStartTime)}
                          </p>
                        ) : (
                          <p style={{ fontSize: 20, margin: "8px", fontWeight: "600", color: "purple", padding: "0px" }}>
                            It's party time! The event is now live!
                          </p>
                        )}
                        {event.timeUntilStart >= 0 && (
                          <p style={{ color: "purple", marginTop: "0px", padding: "0px", margin: "0px" }}>
                            {formatCountdown(event.timeUntilStart)}
                          </p>
                        )}
                        <p style={{ marginTop: "16px", marginBottom: "16px", fontWeight: "600", color: "purple", padding: "0px", margin: "0px" }}>
                          Event name: {event.eventName}
                        </p>
                        <p style={{ marginTop: "0px", marginBottom: "16px", fontWeight: "600", color: "purple", padding: "0px", margin: "0px" }}>
                          Event type: {event.eventType}
                        </p>
                        <p style={{
                          color: "purple",
                          marginTop: "0px",
                          marginBottom: "16px",
                          fontWeight: "600",
                          padding: "0px",
                          margin: "0px"
                        }}>
                          Event difficulty:
                          <span style={{
                            marginLeft: "4px",
                            color: event.eventDifficulty === "Easy" ? "green" :
                              event.eventDifficulty === "Medium" ? "blue" :
                                event.eventDifficulty === "Hard" ? "red" :
                                  event.eventDifficulty === "Very hard" ? "#8B0000" : "black"
                          }}>
                            {event.eventDifficulty}
                          </span>
                        </p>
                        {event.timeUntilStart >= 0 && (
                          <p style={{ marginTop: "16px", fontWeight: "600", color: "purple", padding: "0px", cursor: "pointer" }} onClick={toggleInfoModal}>
                            Need more details? Click Here!
                          </p>
                        )}
                        {!hasRegistered ? (
                          <p style={{ marginTop: "16px", fontWeight: "600", color: "purple", padding: "0px", alignContent: "center" }}>
                            Minimum entry fee: {event.eventFee <= 0 || event.eventFee === undefined ? "Free" : event.eventFee} {event.eventFee <= 0 || event.eventFee === undefined ? "" : "FNNC"}
                          </p>
                        ) : (
                          <p style={{ marginTop: "16px", fontWeight: "600", color: "green", padding: "0px", alignContent: "center" }}>
                            {event.eventFee <= 0 || event.eventFee === undefined ? "" : "Entry fee paid!"}
                          </p>)}
                        {event.timeUntilStart >= 0 ? (
                          hasRegistered ? (
                            <div style={{ height: "20px", marginTop: "16px", marginBottom: "16px", width: "50%", padding: "16px", backgroundColor: "green", border: "2px solid green", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center", alignContent: "center" }}>
                              <p style={{ marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px" }}>
                                You're in! Now we just wait for the event to start!
                              </p>
                            </div>
                          ) : (
                            <div style={{ height: "20px", marginTop: "16px", marginBottom: "16px", width: "50%", padding: "16px", backgroundColor: "purple", border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center", cursor: "pointer", alignContent: "center" }} onClick={() => {
                              toggleRegisterModal();
                              handleEventSpecs(event.eventId, event.eventName, event.eventFee);
                            }}>
                              <p style={{ marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px", alignContent: "center" }}>
                                RSVP now to secure your spot!
                              </p>
                            </div>
                          )
                        ) : (
                          hasRegistered ? (
                            <div style={{ height: "20px", marginTop: "16px", marginBottom: "16px", width: "50%", padding: "16px", backgroundColor: "blue", border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center", cursor: "pointer", alignContent: "center" }} onClick={() => { handleLinkClick(event.eventId); }}>
                              <div style={{ marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px" }}>
                                It's showtime! Click here to enter!
                              </div>
                            </div>
                          ) : (
                            <div style={{ height: "20px", marginTop: "16px", marginBottom: "16px", width: "50%", padding: "16px", backgroundColor: "purple", border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center", cursor: "pointer" }} onClick={() => {
                              toggleRegisterModal();
                              handleEventSpecs(event.eventId, event.eventName, event.eventFee);
                            }}>
                              <p style={{ marginTop: "8px", fontWeight: "600", color: "white", padding: "0px", margin: "0px", alignContent: "center" }}>
                                RSVP now to attend!
                              </p>
                            </div>
                          )
                        )}
                        <p style={{ fontSize: 20, fontWeight: "600", color: "purple", padding: "0px", margin: "0px" }}>
                          Current prize pool: {event.currentBalance.toFixed(2)} FNNC / ${Number(prizePool).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {eventsList.length === 0 && !loading && (
                  <div style={{ width: "100%", marginTop: "16vw", marginBottom: "16px" }}>
                    <div style={{ marginTop: "0px", width: isMobile ? "90%" : "50%", padding: "16px", backgroundColor: "white", opacity: 0.9, border: "2px solid purple", borderRadius: "16px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                      <p style={{ fontSize: 20, margin: "8px", fontWeight: "600", color: "purple", padding: "0px" }}>
                        Looks like the latest event is over.
                      </p>
                      <p style={{ marginTop: "16px", marginBottom: "16px", fontWeight: "600", color: "purple", padding: "0px", margin: "0px" }}>
                        But worry not! The next event will be shown here as soon as registrations are open!
                      </p>
                      <p style={{ marginTop: "0px", marginBottom: "16px", fontWeight: "600", color: "purple", padding: "0px", margin: "0px" }}>
                        Weekly events normally appear around 12 PM on Tuesdays, and they always start at 1 PM on Saturdays.
                      </p>
                      <p style={{ marginTop: "0px", marginBottom: "16px", fontWeight: "600", color: "purple", padding: "0px", margin: "0px" }}>
                        All times are in UTC.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {loading && (
          <div style={{ width: "100%", height: "100%", display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
            <PropagateLoader color="purple" />
          </div>
        )}
        <InfoModal showInfoModal={showInfoModal} toggleInfoModal={toggleInfoModal} isMobile={isMobile} />
        <DisclaimerModal showDisclaimerModal={showDisclaimerModal} toggleDisclaimerModal={toggleDisclaimerModal} isMobile={isMobile} />
        <RegisterToEvent selectedEventFee={selectedEventFee} triggerNotification={triggerNotification} showRegisterModal={showRegisterModal} triggerCloseModal={triggerCloseModal} isMobile={isMobile} selectedEventId={selectedEventId} selectedEventName={selectedEventName} />
        <ToastContainer
          position="bottom-center"
          autoClose={3000} />
        {!showRegisterModal && !showInfoModal && !showDisclaimerModal && (
          <div className={`footer-container${fadeOut ? " fade-out" : ""}`} style={{ position: "sticky", bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)', color: 'purple', textAlign: 'center', padding: '20px', borderTop: '2px solid purple', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', zIndex: 1, width: 'calc(100% - 40px)' }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: '100%' }}>
              <div style={{ display: "flex", alignItems: isMobile ? "" : "center" }}>
                <p style={{ cursor: "pointer", marginTop: "0px", marginBottom: "0px", fontWeight: 600, fontSize: isMobile ? "14px" : "inherit" }} onClick={toggleInfoModal}>
                  FAQ
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ cursor: "pointer", marginTop: "0px", marginBottom: "0px", fontWeight: 600, fontSize: isMobile ? "14px" : "inherit" }} onClick={() => { window.open("https://xeggex.com/market/FNNC_USDT", "_blank"); }}>
                  FNNC price:
                </p>
                <p style={{ cursor: "pointer", marginTop: "0px", marginBottom: "0px", fontWeight: 600, fontSize: isMobile ? "14px" : "inherit" }} onClick={() => { window.open("https://xeggex.com/market/FNNC_USDT", "_blank"); }}>
                  ${Number(priceDetails[0]?.usdValue).toFixed(4)}
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ marginTop: "0px", marginBottom: "0px", fontWeight: 600, fontSize: isMobile ? "14px" : "inherit" }}>
                  Prize pools:
                </p>
                <p style={{ marginTop: "0px", marginBottom: "0px", fontWeight: 600, fontSize: isMobile ? "14px" : "inherit" }}>
                  {totalAmountInEvents.toFixed(2)} FNNC / ${totalPrizePool.toFixed(2)}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: isMobile ? "" : "center" }}>
                <p style={{ cursor: "pointer", marginTop: "0px", marginBottom: "0px", fontWeight: 600, fontSize: isMobile ? "14px" : "inherit" }} onClick={toggleDisclaimerModal}>
                  Disclaimer
                </p>
              </div>
            </div>
          </div>
        )}
      </div></>
  );
}

export default FunhouseDashboardOld;
